html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

body {
    background-color: #eee;
}

.informationExpansion p {
    margin-bottom: 0.5rem;
}

.badgeName span {
    white-space: pre-wrap;
    text-align: center;
}
